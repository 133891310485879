import React, { FunctionComponent } from 'react';

import styles from './MetaValue.module.scss';

interface IMetaValueProps {
  Icon: FunctionComponent<any> | Function
  value?: string | number
}

const MetaValue = ({ Icon = Function.prototype, value = '' }: IMetaValueProps) => {
  return (
    <div className={styles.container}>
      <Icon />
      <span>{value}</span>
    </div>
  );
};

export default MetaValue;
