import { Helmet } from 'react-helmet-async';
import { Routes, Route } from "react-router-dom";

import Layout from '@/components/Layout/Layout';

import Home from '@/pages/Home/Home';
import Inbox from '@/pages/Inbox/Inbox';
import SingleMessage from '@/pages/SingleMessage/SingleMessage';


export default function App() {
  return (
    <>
      <Helmet titleTemplate="BugBug Inbox - %s">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.svg" />
      </Helmet>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/all/" element={<Home />} />
          <Route path="/:name/" element={<Layout />}>
            <Route path="/:name/messages/:messageId/" element={<SingleMessage />} />
            <Route path="/:name/" element={<Inbox />} />
          </Route>
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}