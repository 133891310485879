import { forwardRef, useRef, useImperativeHandle, Fragment, type PropsWithChildren, type FunctionComponent, type MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

export interface ButtonProps {
  className?: string
  type?: 'button' | 'submit'
  variant?: 'default' | 'primary' | 'secondary' | 'inline'
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void
  'data-testid'?: string
  Icon?: FunctionComponent<any> | Function,
  bordered?: boolean
  disabled?: boolean
  noContentWrapper?: boolean
  iconPosition?: 'right' | 'left'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps & PropsWithChildren>((props, ref) => {
  const {
    className,
    variant = 'default',
    children,
    'data-testid': dataTestId,
    Icon = null,
    bordered = false,
    noContentWrapper,
    iconPosition,
    ...buttonProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, []);
  const hasIconOnTheLeft = iconPosition === 'left';

  const renderIcon = () => (
    <span className={classNames(
      styles.iconContainer,
      { [styles.iconPositionRight]: !hasIconOnTheLeft }
    )}>
      {Icon ? <Icon /> : null}
    </span>
  );

  const ContentWrapper = noContentWrapper ? Fragment : (props: PropsWithChildren) => <div>{props.children}</div>;

  return (
    <button
      className={classNames(className, styles.buttonContainer, {
        [styles['button--bordered']]: bordered,
        [styles['button--default']]: variant === 'default',
        [styles['button--primary']]: variant === 'primary',
        [styles['button--secondary']]: variant === 'secondary',
        [styles['button--inline']]: variant === 'inline',
        [styles['button--withIcon']]: !!Icon,
      })}
      ref={buttonRef}
      data-testid={dataTestId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {!!Icon && hasIconOnTheLeft && renderIcon()}
      <ContentWrapper>{children}</ContentWrapper>
      {!!Icon && !hasIconOnTheLeft && renderIcon()}
    </button>
  );
});

export default Button;
