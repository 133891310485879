import classNames from 'classnames';
import styles from './Loader.module.scss';

export type LoaderSize = 'large' | 'default' | 'small'

export type LoaderVariant = 'dark' | 'light'
interface LoaderProps {
  className?: string
  size?: LoaderSize
  variant?: LoaderVariant
  stopped?: boolean
}

const Loader = ({ className, size, variant = 'dark', stopped = false }: LoaderProps) => {
  return (
    <div
      className={classNames(className, styles.loaderBase, {
        [styles['loader--large']]: size === 'large',
        [styles['loader--small']]: size === 'small',
        [styles['loader--light']]: variant === 'light',
        [styles['loader--stopped']]: stopped,
      })}
      data-testid="Loader"
    />
  );
};

export default Loader;
