import { useImperativeHandle, useRef, forwardRef, ReactNode, MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './IconButton.module.scss';

interface Props {
  className?: string
  disabled: boolean
  children: ReactNode
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children = null, ...buttonProps } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, []);

  return (
    <button
      ref={buttonRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      className={classNames(buttonProps.className, styles.container)}
    >
      {children}
    </button>
  )
});

IconButton.displayName = 'IconButton';

export default IconButton;
