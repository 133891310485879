const common = {
  VERSION: process.env.REACT_APP_VERSION,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  ENV: process.env.NODE_ENV,
  API_HOST: process.env.REACT_APP_API_URL,
  API_WS_URL: `wss://${document.location.hostname}/`,

} as const;

const localhost = {
  ...common,
  API_HOST: `http://${document.location.hostname}:3003${common.API_HOST}`,
  API_WS_URL: `ws://${document.location.hostname}:3003/`,
  SENTRY_DSN: '',
} as const;

const testing = {
  ...common,
  ENV: 'testing',
} as const;

const production = {
  ...common,
} as const;

const configs = {
  localhost,
  testing,
  production,
} as const;

export default configs;
