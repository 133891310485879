import React, { useCallback, useState, useRef, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';

import IconButton from '@/components/IconButton';
import Button from '@/components/Button';
import CheckIcon from '@/images/icons/check.svg?react';
import CopyIcon from '@/images/icons/clipboard.svg?react';

import styles from './CopyButton.module.scss';

interface Props {
  className?: string
  value: string
  small?: boolean
}

const CopyButton = ({ className, value, small }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const messageTimeout = useRef<ReturnType<typeof setTimeout>>();

  const handleClick = useCallback(() => {
    copy(value);
    setIsCopied(true);

    messageTimeout.current = setTimeout(() => setIsCopied(false), 2000);
  }, [value]);

  useEffect(() => {
    return () => {
      clearTimeout(messageTimeout.current);
    };
  }, []);

  const Icon = isCopied ? CheckIcon : CopyIcon;

  if (small) {
    return (
      <IconButton
        className={classNames(styles.iconButton, className)}
        onClick={handleClick}
        disabled={isCopied}
      >
        <Icon />
      </IconButton>
    );
  }

  const label = isCopied
    ? 'Copied!'
    : 'Copy to clipboard';

  return (
    <Button
      className={classNames(styles.button, className)}
      Icon={isCopied ? CheckIcon : CopyIcon}
      onClick={handleClick}
      disabled={isCopied}
    >
      {label}
    </Button>
  );
};

export default CopyButton;
