import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCoffee = (props, ref) => /* @__PURE__ */ jsxs("svg", { id: "b063c467-f932-4bd3-8d56-212cf211223f", "data-name": "bdea55e9-dace-4698-8387-e54a9dc4b418", xmlns: "http://www.w3.org/2000/svg", width: 40, height: 32, viewBox: "0 0 35 32", className: "sc-igHpSv cDdSGs", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "coffee-pixel-02" }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 2, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 13, y: 4, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 6, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 8, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 12, width: 2.36, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 9, y: 12, width: 2, height: 10, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 13, y: 12, width: 13, height: 8, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 15, y: 20, width: 11, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 13, y: 24, width: 9, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 7, y: 28, width: 21, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 22, width: 13, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 26, y: 14, width: 4, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 30, y: 16, width: 2, height: 4, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 26, y: 20, width: 4, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 2, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 18, y: 4, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 6, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 16, y: 8, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 21, y: 2, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 23, y: 4, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 21, y: 6, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } }),
  /* @__PURE__ */ jsx("rect", { x: 21, y: 8, width: 2, height: 2, style: {
    fill: "rgb(236, 237, 237)"
  } })
] });
const ForwardRef = forwardRef(SvgCoffee);
export default ForwardRef;
