import { type ChangeEvent, useCallback, useRef, useState } from 'react';
import Jabber from 'jabber';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Logo from '@/images/logo.svg?react';
import Button from '@/components/Button';
import Input from '@/components/Input';
import styles from './Home.module.scss';

const emailValidator = yup.string().email().required();
const mailDomain = `@${process.env.REACT_APP_INBOX_DOMAIN}`;

export default function Home() {
  const navigate = useNavigate();
  const jabber = useRef(new Jabber());
  const [email, setEmail] = useState(jabber.current.createWord(15, false));
  const [emailError, setEmailError] = useState<string | boolean>(false);
  const parsedEmail = email.replace('@', '');

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    const isValid = emailValidator.isValidSync(`${newEmail}${mailDomain}`);
    if (isValid) {
      setEmailError(false);
      setEmail(newEmail);
    } else {
      setEmailError('Email is invalid');
    }
  }, []);

  const handleGenerate = useCallback(() => {
    setEmail(jabber.current.createWord(15, false));
  }, []);

  const handleOpenInbox = useCallback((event: any) => {
    event.preventDefault();
    navigate(`/${encodeURIComponent(parsedEmail)}/`.toLowerCase())
  }, [parsedEmail, navigate]);

  return (
    <div className={styles.container}>
      <h1 className={styles.headline}>
        BugBug Testing Inbox
      </h1>
      <p className={styles.subHeadline}>
        Speed up your email testing. <br />
        Easily verify and test your user signup or login process.
      </p>

      <form className={styles.content} onSubmit={handleOpenInbox}>
        <Logo className={styles.logo} />
        <Input
          name="email"
          value={parsedEmail}
          onChange={handleEmailChange}
          endAdornment={mailDomain}
          error={emailError}
          autoFocus
        />
        <div className={styles.buttonsContainer}>
          <Button onClick={handleGenerate} type="button">Randomize</Button>
          <Button variant="primary" disabled={!!emailError} type="submit">View inbox</Button>
        </div>
      </form>
      <span>
        v{process.env.REACT_APP_VERSION}
      </span>
    </div>
  )
}
