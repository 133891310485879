import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgLogo = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 290.3 90.68", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("style", { children: ".cls-1{fill:#461e56;}" }) }),
  /* @__PURE__ */ jsx("title", { children: "bubug-logo" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", "data-name": "Layer 2", children: /* @__PURE__ */ jsxs("g", { id: "Layer_1-2", "data-name": "Layer 1", children: [
    /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "36.28 54.42 36.28 18.14 18.14 18.14 18.14 0 0 0 0 54.42 36.28 54.42" }),
    /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "90.69 54.42 90.69 18.14 72.55 18.14 72.55 0 54.41 0 54.41 54.42 90.69 54.42" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M113.45,68h9v9h-9Zm0-13.53h-4.57v27.1H127V63.38H113.41Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M178.76,68h9v9h-9Zm13.57-4.57H174.19V81.52h13.57v4.59H174.19v4.57h18.14Z" }),
    /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "155.21 76.95 146.21 76.95 146.21 63.38 141.64 63.38 141.64 81.52 159.78 81.52 159.78 63.38 155.21 63.38 155.21 76.95" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M211.39,68h9v9h-9Zm0-13.53h-4.57l0,27.1H225V63.38H211.35Z" }),
    /* @__PURE__ */ jsx("path", { className: "cls-1", d: "M276.73,68h9v9h-9Zm13.57-4.57H272.16V81.52h13.57v4.59H272.16v4.57H290.3Z" }),
    /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "252.97 76.95 243.97 76.95 243.97 63.38 239.4 63.38 239.4 81.52 257.54 81.52 257.54 63.38 252.97 63.38 252.97 76.95" }),
    /* @__PURE__ */ jsx("polygon", { className: "cls-1", points: "72.56 63.5 72.56 72.56 18.14 72.56 18.14 63.49 0 63.49 0 81.63 18.14 81.63 18.14 90.68 72.57 90.68 72.57 81.62 90.69 81.62 90.69 63.5 72.56 63.5" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
